import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Grid,
  Card,
  CardMedia,
  Typography,
  Box,
  Avatar,
  CircularProgress,
  Paper,
  Button,
} from '@mui/material';
import { NavLink, useParams } from 'react-router-dom';
import Header from '../components/Header';
import MainNav from '../components/MainNav';
import Footer from '../components/Footer';
import PaginationBlock from '../components/PaginationBlock';
import MetaTags from '../components/MetaTags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faUserPlus, faBookmark } from '@fortawesome/free-solid-svg-icons';

const Technology = () => {
  const {selectsubcategory, innercategory } = useParams();
  const [data, setData] = useState(null);
  const [loaderState, setLoaderState] = useState(true);
  const [navlist, setNavlist] = useState(null);
  const [mainnavlist, setMainNavlist] = useState(null);
  const [pageFooter, setPageFooter] = useState(null);
  const [pageTitle1, setPageTitle1] = useState('');
  const [pageTitle2, setPageTitle2] = useState('');
  const [contentPara, setContentPara] = useState('');
  const [activeFlag, setActiveFlag] = useState('0');
  const [allCities, setAllCities] = useState([]);
  const [total_pages, setTotalPages] = useState(0);
  const [total_records, setTotalRecords] = useState(0);
  const [active_page, setActivePage] = useState(0);
  const [page_content_views, setPageContentView] = useState(0);
  const [pagemetatags, setPageMetaTags] = useState([]);
  const onclickOpenURL = (pageurl) => {
      window.location = "/"+pageurl;
  }
  const fetchPageData = async () => {
    try {
      const response = await axios.post('https://broadwayoriginals.com/apis/index.php', { technology: '', page: '0' });
      document.title = "Broadway Originals | " + response.data.data.page_title.part1 + " " + response.data.data.page_title.part2;
      setNavlist(
        <Header 
          navlist={JSON.stringify(response.data.navlist)} 
          socailmedia={JSON.stringify(response.data.socailmedia)} 
        />
      );
      setMainNavlist(<MainNav navlist={JSON.stringify(response.data.navlist)} />);
      setPageMetaTags(response.data.data.meta_tags);
      setData(response.data.data.page_data);
      setPageTitle1(response.data.data.page_title.part1);
      setPageTitle2(response.data.data.page_title.part2);
      setTotalPages(response.data.data.page_data[0].total_pages);
      setTotalRecords(response.data.data.page_data[0].total_records);
      setActivePage(response.data.data.page_data[0].active_page);
      setContentPara(`Discover a complete guide to ${response.data.topics}.`);
      setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)} />);
      setLoaderState(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoaderState(false);
    }
  };
 
const fetchPages = async (page_number) => {    
  try { 
      setLoaderState(true);   
      setData(null);
      const response = await axios.post('https://broadwayoriginals.com/apis/index.php', {technology: "", page: page_number});
      setData(response.data.data.page_data);
      setAllCities(response.data.allcities);
      setPageTitle1(response.data.data.page_title.part1);
      setPageTitle2(response.data.data.page_title.part2);
      setNavlist(
        <Header 
          navlist={JSON.stringify(response.data.navlist)} 
          socailmedia={JSON.stringify(response.data.socailmedia)} 
        />
      );
      setMainNavlist(<MainNav navlist={JSON.stringify(response.data.navlist)} />);
      setPageMetaTags(response.data.data.meta_tags);
      document.title = "Broadway Originals | "+response.data.data.page_title.part1+" "+response.data.data.page_title.part2;
      setTotalPages(response.data.data.page_data[0].total_pages);
      setTotalRecords(response.data.data.page_data[0].total_records);
      setActivePage(response.data.data.page_data[0].active_page);
      setContentPara('Discover a complete guide to where to '+response.data.topics+'. Find curated reviews & recommendations.');
      setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);
      setLoaderState(false);
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};
  useEffect(() => {
    fetchPageData();
  }, []);

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          padding: '0px !important',
          background: 'url(https://source.unsplash.com/random/1920x1080)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100vh',
          color: '#fff',
        }}
      >
        {navlist}
        {mainnavlist}
        <MetaTags tags={pagemetatags} />
        {loaderState && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
            <CircularProgress />
          </Box>
        )}

        

        <Container maxWidth="lg" sx={{ padding: { xs: '20px', md: '0px' }, marginTop: '40px' }}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box sx={{ perspective: '1000px', textAlign: 'center' }}>
                <Typography
                  variant="h2"
                  gutterBottom
                  sx={{
                    color: 'rgba(0, 0, 0, 0.87)',
                    fontSize: '2.75rem',
                    textShadow: '0 5px 10px rgba(0, 0, 0, 0.7)',
                    display: 'inline-block',
                    transition: 'transform 0.3s ease',
                    transformStyle: 'preserve-3d',
                    '&:hover': {
                      transform: 'rotateY(10deg) rotateX(10deg)',
                    },
                  }}
                >
                  {pageTitle1} {pageTitle2}
                </Typography>
              </Box>
              <Typography
                variant="body1"
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  textAlign: 'center',
                  marginBottom: '20px',
                  maxWidth: '800px',
                  margin: '0 auto',
                }}
              >
                {contentPara}
              </Typography>
            </Grid>

            {/* Data Rendering - Only show categories with posts */}
            {data && data.length > 0 ? (
              data
                .filter((category) => category.posts.length > 0)              
                .map((category) => (
                  <Grid item xs={12} key={category.id}>
                    
                    <Grid container spacing={3}>
                       {category.posts.length > 0 && page_content_views == "0" && setPageContentView(1)}
                      {category.posts.map((post) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={post.post_id}>
                         
                          <Card
                            sx={{
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                              '&:hover': {
                                transform: 'scale(1.05)',
                                boxShadow: '0 12px 20px rgba(0, 0, 0, 0.3)',
                              },
                            }}
                          >
                            <CardMedia
                              component="img"
                              height="180"
                              image={`https://broadwayoriginals.com/${post.post_img}`}
                              alt={post.post_title}
                              sx={{ objectFit: 'cover' }}
                            />
                            <Box sx={{ padding: 2, flexGrow: 1 }}>
                              <NavLink to={`/${post.custom_url}`} style={{ textDecoration: 'none' }}>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: 'bold',
                                    marginBottom: 1,
                                    color: 'black',
                                    overflow: 'hidden',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 3, // Limit to 3 lines
                                  }}
                                >
                                  {post.post_title}
                                </Typography>
                              </NavLink>

                              {/* Post details: views, followers, author */}
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', color: 'gray' }}>
                                  <FontAwesomeIcon icon={faEye} style={{ marginRight: '5px' }} />
                                  <Typography variant="body2">{post.post_views || 0} views</Typography>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', color: 'gray' }}>
                                  <FontAwesomeIcon icon={faUserPlus} style={{ marginRight: '5px' }} />
                                  <Typography variant="body2">{post.post_followers || 0} followers</Typography>
                                </Box>

                                
                              </Box>

                              {/* Author Information */}
                              {post.author_id > 0 && 
                              <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                
                                <Avatar
                                  src={`https://broadwayoriginals.com/${post.author.img}`}
                                  alt={post.author.name}
                                  sx={{ width: 30, height: 30, marginRight: '10px' }}
                                />
                                <Typography variant="body2" sx={{ color: 'gray' }}>
                                  {post.author.name}
                                </Typography>
                              </Box>
                              }
                            </Box>
                          </Card>
                          
                        </Grid>
                      ))}

                    </Grid>

                  </Grid>
                ))
            ) : (
              <Typography variant="body1" sx={{ textAlign: 'center', marginTop: 3, color: '#888', width: '100%' }}>
                
              </Typography>
            )}
            {page_content_views == "0" && 
              <Typography variant="body1" sx={{ textAlign: 'center', marginTop: 3, color: '#888', width: '100%' }}>
                No data available.
             </Typography>
            }
            {/* Pagination */}
            <Grid item xs={12} sx={{ mb: 3 }}>
            {total_pages > 1 && (             
              <PaginationBlock total_pages={total_pages} active_page={active_page} onPageChange={fetchPages} city_id='0' sub_category_id="0" inner_category_id="0" />
            )}
            </Grid>
          </Grid>
        </Container>
      </Container>
      {pageFooter}
    </>
  );
};

export default Technology;
