import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardMedia,
  Typography,
  Badge,
  Box,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from 'react-router-dom';
import Slider from "react-slick"; // Import react-slick
import food_icon from "../images/food.png";
import '../css/styles.css'; // Import your styles

function Food({ foodlist }) {
  const data_list = JSON.parse(foodlist);

  const onclickOpenFood = () => {
    window.location = "/food";
  };

  // Settings for the carousel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      {data_list.length > 0 && (
        <Container sx={{ marginBottom: "60px", paddingX: { xs: 2, sm: 3 } }}>
          {/* Header Section */}
          <Grid container sx={{ marginBottom: "10px" }}>
            <Grid item xs={12} md={10}>
              <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '24px', md: '32px' } }}>
                <img src={food_icon} alt="Food" style={{ marginRight: 10 }} loading="lazy" />
                Food
              </Typography>
            </Grid>
            <Grid item xs={12} md={2} sx={{ textAlign: 'end' }} className='show-on-desktop'>
              <Badge
                sx={{
                  fontSize: "18px",
                  backgroundColor: "white",
                  color: "#00a8a9",
                  borderRadius: "20px",
                  padding: "10px",
                  cursor: "pointer",
                  border: "1px solid #00a8a9",
                  paddingLeft: "25px",
                }}
                onClick={onclickOpenFood}
              >
                <span style={{ color: "#00a8a9", fontWeight: 200 }}>
                  Explore More <FontAwesomeIcon icon={faChevronRight} style={{ paddingLeft: "10px" }} />
                </span>
              </Badge>
            </Grid>
          </Grid>

          {/* Main Content Section for Desktop */}
          <Grid container spacing={2} sx={{ marginTop: "20px", display: { xs: 'none', md: 'flex' } }}>
            {data_list.map((item, index) => (
              <Grid item xs={12} md={3} key={index}>
                <Card sx={{ backgroundColor: 'dark', color: 'white', height: '100%', position: 'relative' }}>
                  <CardMedia
                    component="img"
                    image={`https://broadwayoriginals.com/${item.img}`}
                    alt={item.name}
                    sx={{ height: '100%', borderRadius: '20px', objectFit: 'cover' }}
                    loading="lazy"
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent background for better visibility
                      padding: 1,
                    }}
                  >
                    <NavLink to={`/food/${item.url}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                      <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold' }}>
                        {item.name}
                      </Typography>
                    </NavLink>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* Carousel for Mobile */}
          <Grid className="hide-on-desktop">
            <Slider {...settings} sx={{ marginTop: "45px" }}>
              {data_list.map((item, index) => (
                <Box key={index}>
                  <Card sx={{ backgroundColor: 'dark', color: 'white', height: '100%' }}>
                    <CardMedia
                      component="img"
                      image={`https://broadwayoriginals.com/${item.img}`}
                      alt={item.name}
                      sx={{ height: '100%', borderRadius: '20px', objectFit: 'cover' }}
                      loading="lazy"
                    />
                    <Box sx={{ position: 'absolute', bottom: 0, padding: 1 }}>
                      <NavLink to={`/food/${item.url}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold' }}>
                          {item.name}
                        </Typography>
                      </NavLink>
                    </Box>
                  </Card>
                </Box>
              ))}
            </Slider>
          </Grid>

          {/* Mobile View "Explore More" Button */}
          <Grid container justifyContent="flex-end" sx={{ marginTop: 1, display: { xs: 'flex', md: 'none' } }}>
            <Grid item xs={12}>
              <Badge
                sx={{
                  fontSize: "18px",
                  background: "none",
                  color: "#00a8a9",
                  borderRadius: "20px",
                  padding: "10px",
                  cursor: "pointer",
                  border: "1px solid #00a8a9",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between"
                }}
                onClick={onclickOpenFood}
              >
                <span style={{ color: "#00a8a9", fontWeight: 200 }}>Explore More</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Badge>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}

export default Food;
