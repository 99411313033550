import React from 'react';
import { Box, Grid, Card, CardMedia, Typography, IconButton } from '@mui/material';
import Container from '@mui/material/Container';
import Slider from 'react-slick';
import trendingIcon from '../images/trending-icon.png'; // Assuming this is the trending icon
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'; // Material-UI icons for arrows

const Trending = ({ trandingdata }) => {
  const trending_list = JSON.parse(trandingdata);

  // Custom Next Arrow
  const NextArrow = ({ onClick }) => {
    return (
      <IconButton
        onClick={onClick}
        sx={{
          position: 'absolute',
          top: '50%',
          right: '10px', // Adjust right position to ensure it's visible
          transform: 'translateY(-50%)',
          zIndex: 2, // Ensure it's above the slider
          backgroundColor: '#ffffff', // Background color for better visibility
          '&:hover': {
            backgroundColor: '#f0f0f0',
          },
        }}
      >
        <ArrowForwardIos />
      </IconButton>
    );
  };

  // Custom Previous Arrow
  const PrevArrow = ({ onClick }) => {
    return (
      <IconButton
        onClick={onClick}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '10px', // Adjust left position to ensure it's visible
          transform: 'translateY(-50%)',
          zIndex: 2, // Ensure it's above the slider
          backgroundColor: '#ffffff', // Background color for better visibility
          '&:hover': {
            backgroundColor: '#f0f0f0',
          },
        }}
      >
        <ArrowBackIos />
      </IconButton>
    );
  };

  // Slider settings for react-slick
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box sx={{ mt: 1, mb: 8, position: 'relative' }}>
      <Container maxWidth="lg">
        <Grid container justifyContent="center" sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', mt: 4 }}>
              <img src={trendingIcon} alt="Trending Icon" style={{ marginRight: '8px' }} />
              <Typography variant="h4" sx={{ color: '#00A8AA', fontWeight: 'bold' }}>
                Trending
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Slider {...sliderSettings}>
          {trending_list.map((item, index) => (
            <Box key={index} sx={{ p: 2 }}>
              <Card sx={{ borderRadius: '20px', position: 'relative', width: '100%', height: '330px' }}>
                <CardMedia
                  component="img"
                  image={`https://broadwayoriginals.com/${item.post_img}`}
                  alt={item.post_title}
                  sx={{ height: '100%', borderRadius: '20px' }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'rgba(33, 37, 41, 0.55)',
                    borderRadius: '20px',
                    padding: '10px',
                  }}
                >
                  <Typography
                    variant="h6"
                    component={Link}
                    to={`/${item.custom_url}`}
                    sx={{
                      color: 'white',
                      textDecoration: 'none',
                      fontSize: '18px',
                      fontWeight: 'bold',
                    }}
                  >
                    {item.post_title}
                  </Typography>
                </Box>
              </Card>
            </Box>
          ))}
        </Slider>
      </Container>
    </Box>
  );
};

export default Trending;
