import React, { useState } from 'react';
import { Container, Grid, TextField, Button, Typography, Link, Box, InputAdornment } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebookF, faXTwitter, faLinkedinIn, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import footerimage from '../images/white.png';

function Footer(nav_list) {
  const nav_list_json = JSON.parse(nav_list.nav_list);
  const socailmedia_json = JSON.parse(nav_list.socailmedia);
  const [newsletteremail, setNewsletterEmail] = useState("");

  const renderMainNavItems = (data) => {
    return data.map((category) => (
      <Grid item xs={6} key={category.category_name}>
        <Link href={category.custom_url ? `/${category.custom_url}` : "/"} underline="none" color="inherit">
          <Typography variant="body1" sx={{ color: "#cfd8dc" }}>{category.category_name}</Typography>
        </Link>
      </Grid>
    ));
  };

  const handleNewsletter = async () => {
    try {
      if (newsletteremail !== "") {
        const response = await axios.get('https://broadwayoriginals.com/apis/index.php?newsletter=' + newsletteremail);
        if (response.data.status === "1") {
          toast.success(response.data.msg);
          setNewsletterEmail("");
        } else {
          toast.error(response.data.msg);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const updateNewsletter = (event) => {
    setNewsletterEmail(event.target.value);
  };

  return (
    <Box sx={{ backgroundColor: '#212121', color: '#ffffff', paddingY: '60px' }}>
      <ToastContainer />
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          {/* Social Media and Logo Section */}
          <Grid item xs={12} md={3}>
            <img src={footerimage} alt="footer logo" style={{ width: "70%", marginBottom: "20px" }} />
            <Box>
              {socailmedia_json.map((mediadata) => (
                <Link href={mediadata.link} target="_blank" sx={{ marginRight: '15px' }} key={mediadata.name}>
                  {mediadata.name === "facebook" && <FontAwesomeIcon icon={faFacebookF} style={{ color: "#cfd8dc", fontSize: "24px" }} />}
                  {mediadata.name === "twitter" && <FontAwesomeIcon icon={faXTwitter} style={{ color: "#cfd8dc", fontSize: "24px" }} />}
                  {mediadata.name === "linkedin" && <FontAwesomeIcon icon={faLinkedinIn} style={{ color: "#cfd8dc", fontSize: "24px" }} />}
                  {mediadata.name === "instagram" && <FontAwesomeIcon icon={faInstagram} style={{ color: "#cfd8dc", fontSize: "24px" }} />}
                  {mediadata.name === "youtube" && <FontAwesomeIcon icon={faYoutube} style={{ color: "#cfd8dc", fontSize: "24px" }} />}
                  {mediadata.name === "indeed" && <FontAwesomeIcon icon={faLinkedinIn} style={{ color: "#cfd8dc", fontSize: "24px" }} />}
                </Link>
              ))}
            </Box>
          </Grid>

          {/* Most Searches Section */}
          <Grid item xs={12} md={3}>
            <Typography variant="h6" sx={{ color: "#ffffff", marginBottom: "20px", fontWeight: 'bold' }}>Most Searches</Typography>
            <Grid container spacing={1}>
              {renderMainNavItems(nav_list_json)}
            </Grid>
          </Grid>

          {/* Help Section */}
          <Grid item xs={12} md={2}>
            <Typography variant="h6" sx={{ color: "#ffffff", marginBottom: "20px", fontWeight: 'bold' }}>Help</Typography>
            <Box>
              <Link href="/advertise" underline="none" color="inherit">
                <Typography variant="body1" sx={{ color: "#cfd8dc", marginBottom: "8px" }}>Advertise</Typography>
              </Link>
              <Link href="/contact" underline="none" color="inherit">
                <Typography variant="body1" sx={{ color: "#cfd8dc", marginBottom: "8px" }}>Contact Us</Typography>
              </Link>
              <Link href="/about" underline="none" color="inherit">
                <Typography variant="body1" sx={{ color: "#cfd8dc", marginBottom: "8px" }}>About Us</Typography>
              </Link>
              <Link href="/work-with-us" underline="none" color="inherit">
                <Typography variant="body1" sx={{ color: "#cfd8dc", marginBottom: "8px" }}>Work with Us</Typography>
              </Link>
              <Link href="/privacy-policy" underline="none" color="inherit">
                <Typography variant="body1" sx={{ color: "#cfd8dc", marginBottom: "8px" }}>Privacy Policy</Typography>
              </Link>
            </Box>
          </Grid>

          {/* Newsletter Section */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ color: "#ffffff", marginBottom: "20px", fontWeight: 'bold' }}>Newsletter</Typography>
            <Typography sx={{ color: "#cfd8dc", marginBottom: "20px" }}>Subscribe to our mailing list for daily updates.</Typography>
            <TextField
              placeholder="Your Email Address"
              variant="outlined"
              value={newsletteremail}
              onChange={updateNewsletter}
              fullWidth
              sx={{
                backgroundColor: 'white',
                borderRadius: '5px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#00a8aa',
                  },
                  '&:hover fieldset': {
                    borderColor: '#00a8aa',
                  },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      onClick={handleNewsletter}
                      sx={{
                        backgroundColor: '#00a8aa',
                        color: 'white',
                        borderRadius: '5px',
                        paddingX: '20px',
                        '&:hover': {
                          backgroundColor: '#008b8d',
                        },
                      }}
                    >
                      Subscribe
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        {/* Footer Bottom Section */}
        <Box sx={{ borderTop: '1px solid #424242', marginTop: '40px', paddingTop: '20px' }}>
          <Grid container justifyContent="center">
            <Typography variant="body2" sx={{ color: '#cfd8dc', textAlign: 'center' }}>
              <Link href="https://www.linkedin.com/in/ch-dheeraj-krishna-rao/" target="_blank" underline="none" sx={{ color: '#00a8aa' }}>
                Copyright © 2023 Designed & Developed by BnB Infotech Freelancing Team, All Rights Reserved
              </Link>
            </Typography>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
