import React from 'react';
import {
  Box,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Badge,
  Avatar,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from 'react-router-dom';
import technology_img from '../images/Technology.png';

function Technology({ todaynews }) {
  const todaynews_list = JSON.parse(todaynews);

  const onclickOpenTechnology = () => {
    window.location = "/technology";
  };

  return (
    <>
      {todaynews_list.length > 0 && (
        <Container maxWidth="lg" sx={{ mb: 8 }}>
          {/* Header Section */}
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} md={10}>
              
              <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', color: '#00a8a9', fontSize: { xs: '24px', md: '32px' } }}>
                <Avatar src={technology_img} alt="Technology" sx={{ marginRight: 2, borderRadius: "0px", width: "auto", height: "auto" }} />
                Technology
              </Typography>

            </Grid>
            <Grid item xs={12} md={2} sx={{ textAlign: 'end', display: { xs: 'none', md: 'block' } }}>
              <Badge
                sx={{
                  fontSize: "18px",
                  background: "white",
                  color: "#00a8a9",
                  borderRadius: "20px",
                  padding: "10px",
                  cursor: "pointer",
                }}
                onClick={onclickOpenTechnology}
              >
                <span style={{ color: "#00a8a9", fontWeight: 200 }}>
                  Explore More <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: "15px", paddingLeft: "10px" }} />
                </span>
              </Badge>
            </Grid>
          </Grid>

          {/* Main Grid Content */}
          <Grid container spacing={2} sx={{ alignItems: 'stretch' }}>
            {/* First Block (Main Article) */}
            <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
              {todaynews_list.map((item, index) => (
                index === 0 && (
                  <Card key={item.id} sx={{ border: "none", borderRadius: "20px", mb: 2, background: "transparent", boxShadow: 'none', display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <CardMedia
                      component="img"
                      image={`https://broadwayoriginals.com/${item.post_img}`}
                      loading="lazy"
                      sx={{
                        borderRadius: "20px",
                        height: { xs: '240px', md: '357px' }, // Responsive height for the image
                        objectFit: 'cover',
                      }}
                    />
                    <CardContent sx={{ padding: "0", mt: 1, background: "transparent", flexGrow: 1 }}>
                      <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar src={`https://broadwayoriginals.com/${item.author.img}`} alt={item.author.name} sx={{ width: 30, height: 30, mr: 1 }} />
                        <span style={{ color: "rgb(50, 19, 19)" }}>{item.author.name}</span>
                      </Typography>
                      <Typography
                        variant="h6"
                        component={NavLink}
                        to={item.custom_url}
                        sx={{
                          textDecoration: 'none',
                          color: 'inherit',
                          fontWeight: 'bold',
                          marginTop: '8px',
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                        }}
                      >
                        {item.post_title}
                      </Typography>
                    </CardContent>
                  </Card>
                )
              ))}
            </Grid>

            {/* Second Block (Other Articles) */}
            <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
              {todaynews_list.map((item1, index1) => (
                index1 !== 0 && (
                  <Card key={item1.id} sx={{ display: 'flex', mb: 2, borderRadius: '20px', flexDirection: 'row', background: "transparent", boxShadow: 'none', flexGrow: 1 }}>
                    <CardMedia
                      component="img"
                      image={`https://broadwayoriginals.com/${item1.post_img}`}
                      alt={item1.post_title}
                      sx={{
                        width: { xs: '120px', md: '151px' }, // Responsive width for smaller screens
                        height: 'auto', // Maintain the aspect ratio of the image
                        borderRadius: '20px',
                      }}
                    />
                    <CardContent sx={{ flex: '1 0 auto', padding: 1, background: "transparent", boxShadow: 'none' }}>
                      <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar src={`https://broadwayoriginals.com/${item1.author.img}`} alt={item1.author.name} sx={{ width: 30, height: 30, mr: 1 }} />
                        <span style={{ color: "rgb(50, 19, 19)" }}>{item1.author.name}</span>
                      </Typography>
                      <Typography
                        variant="h6"
                        component={NavLink}
                        to={item1.custom_url}
                        sx={{
                          textDecoration: 'none',
                          color: 'inherit',
                          fontWeight: 'bold',
                          marginTop: '8px',
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                        }}
                      >
                        {item1.post_title}
                      </Typography>
                    </CardContent>
                  </Card>
                )
              ))}
            </Grid>
          </Grid>

          {/* Mobile-Only "Explore More" Button */}
          <Grid container justifyContent="flex-end" sx={{ mt: 2, display: { xs: 'flex', md: 'none' } }} className="mobile-view-btn">
            <Grid item xs={12} sx={{ padding: 0 }}>
              <Badge
                sx={{
                  fontSize: "18px",
                  background: "none",
                  color: "#00a8a9",
                  borderRadius: "20px",
                  padding: "10px",
                  cursor: "pointer",
                  border: "1px solid #00a8a9",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={onclickOpenTechnology}
              >
                <span style={{ color: "#00a8a9", fontWeight: 200 }}>Explore More</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Badge>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}

export default Technology;
