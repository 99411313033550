import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardMedia,
  Typography,
  Avatar,
  Box,
  Button
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from 'react-router-dom';
import travelicon from "../images/travel.png";

function Travel({ data }) {
  const data_list = data ? JSON.parse(data) : [];

  // If no data, return nothing
  if (data_list.length === 0) {
    return null;
  }

  const onclickOpenTravel = () => {
    window.location = "/travel";
  };

  return (
    <Container sx={{ marginBottom: "50px" }}>
      {/* Header Section */}
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} md={10}>
          <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={travelicon} alt="Travel" style={{ marginRight: 10 }} loading="lazy" />
            Travel
          </Typography>
        </Grid>
        <Grid item xs={12} md={2} sx={{ textAlign: 'end', padding: 0 }}>
          {/* Unique Explore More Button */}
          <Button
            onClick={onclickOpenTravel}
            variant="contained"
            sx={{
              width: "100%",
              background: "linear-gradient(45deg, #00a8a9, #00796b)",
              borderRadius: "30px",
              padding: "12px 16px",
              borderRadius: "20px",
              fontSize: "18px",
              fontWeight: "bold",
              color: "#fff",
              textTransform: "none",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "relative",
              overflow: "hidden",
              transition: "transform 0.3s ease",
              lineHeight: "1", // Set line height to 1
              "&:hover": {
                transform: "translateY(-2px)",
                boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)"
              },
              "&:active": {
                transform: "translateY(0px)",
                boxShadow: "none"
              }
            }}
          >
            <span>Explore More</span>
            <FontAwesomeIcon icon={faChevronRight} style={{ marginLeft: "10px", transition: "transform 0.3s" }} />
          </Button>
        </Grid>
      </Grid>

      {/* Travel Content Section */}
      <Grid container spacing={4} sx={{ marginTop: 1 }}>
        {data_list.map((item1, index) => (
          <Grid 
            item 
            xs={12} 
            md={data_list.length === 1 ? 12 : 6}  // Full width if only one post, else half width
            key={index}
          >
            <Card
              sx={{
                borderRadius: "21px",
                border: "none",
                position: 'relative',
                transition: "transform 0.4s ease, box-shadow 0.4s ease",  // Smooth transition
                "&:hover": {
                  transform: "scale(1.05)",  // Scale on hover
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.3)"  // Add shadow on hover
                },
              }}
            >
              <CardMedia
                component="img"
                image={`https://broadwayoriginals.com/${item1.post_img}`}
                alt={item1.post_title}
                sx={{
                  borderRadius: "20px",
                  height: data_list.length === 1 ? "300px" : "100%",  // Set a fixed height for a single post
                  maxHeight: "300px",  // Limit the height for large images
                  objectFit: "cover"  // Ensure image scales nicely
                }}
                loading="lazy"
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.33)",
                  borderRadius: "21px",
                  padding: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <NavLink to={`/${item1.custom_url}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: 'white',
                      fontWeight: data_list.length === 1 ? 'bold' : 'normal',
                      fontSize: data_list.length === 1 ? '1.5rem' : '1rem',  // Larger font when only one post
                    }}
                  >
                    {item1.post_title}
                  </Typography>
                </NavLink>
                <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: 2 }}>
                  <Avatar
                    src={`https://broadwayoriginals.com/${item1.author.img}`}
                    alt={item1.author.name}
                    sx={{ width: 40, height: 40, marginRight: 1 }}
                  />
                  <Typography variant="body2" sx={{ color: 'white' }}>
                    {item1.author.name}
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Mobile View "Explore More" Button */}
      <Grid container justifyContent="flex-end" sx={{ marginTop: 4, display: { xs: 'flex', md: 'none' } }}>
        <Grid item xs={12}>
          <Button
            onClick={onclickOpenTravel}
            variant="contained"
            sx={{
              width: "100%",
              background: "linear-gradient(45deg, #00a8a9, #00796b)",
              borderRadius: "30px",
              padding: "6px 16px",  // Updated padding
              fontSize: "18px",
              fontWeight: "bold",
              color: "#fff",
              textTransform: "none",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "relative",
              overflow: "hidden",
              transition: "transform 0.3s ease",
              lineHeight: "1", // Set line height to 1
              "&:hover": {
                transform: "translateY(-2px)",
                boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)"
              },
              "&:active": {
                transform: "translateY(0px)",
                boxShadow: "none"
              }
            }}
          >
            <span>Explore More</span>
            <FontAwesomeIcon icon={faChevronRight} style={{ marginLeft: "10px", transition: "transform 0.3s" }} />
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Travel;
