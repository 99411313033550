import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Avatar,
  CircularProgress,
  Paper,
  Button,
} from '@mui/material';
import { NavLink, useParams } from 'react-router-dom';
import Header from '../components/Header';
import MainNav from '../components/MainNav';
import Footer from '../components/Footer';
import MetaTags from '../components/MetaTags';

const Withus = () => { 
  const [data, setData] = useState(null);
  const [loaderState, setLoaderState] = useState(true);
  const [navlist, setNavlist] = useState(null);
  const [mainnavlist, setMainNavlist] = useState(null);
  const [pageFooter, setPageFooter] = useState(null);
  const [pagemetatags, setPageMetaTags] = useState([]);
  const [pagedata, setPageData] = useState([]);
  const onclickOpenURL = (pageurl) => {
      window.location = "/"+pageurl;
  }
  const fetchPageData = async () => {
        try {
        setLoaderState(true);
        const response = await axios.get('https://broadwayoriginals.com/apis/index.php?work-with-us');
        document.title = "Broadway Originals | Work with us";
        setPageData(response.data.pagedata);
        setData(response.data);
        setNavlist(
            <Header 
              navlist={JSON.stringify(response.data.navlist)} 
              socailmedia={JSON.stringify(response.data.socailmedia)} 
            />
          );
        setMainNavlist(<MainNav navlist={JSON.stringify(response.data.navlist)} />);
       // setPageMetaTags(response.data.data.meta_tags);
        setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)} />);
        
        setLoaderState(false);
        } catch (error) {
        console.error('Error fetching data:', error);
        }
    };
  useEffect(() => {
    fetchPageData();
  }, []);

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          padding: '0px !important',
          background: 'url(https://source.unsplash.com/random/1920x1080)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100vh',
          color: '#fff',
        }}
      >
        {navlist}
        {mainnavlist}
        <MetaTags tags={pagemetatags} />
        {loaderState && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
            <CircularProgress />
          </Box>
        )}
        <Container maxWidth="lg" sx={{ padding: { xs: '20px', md: '0px' }, marginTop: '40px', color: 'black' }}>
        <Typography
            variant="h2"
            gutterBottom
            sx={{
                color: 'rgb(0, 123, 124)',
                fontSize: '1.75rem',
                fontWeight: 'bold',
                display: 'inline-block',
                transition: 'transform 0.3s ease',
                transformStyle: 'preserve-3d',
                    '&:hover': {
                      transform: 'rotateY(10deg) rotateX(10deg)',
                    },
                }}
        >
            Work with us
        </Typography>
        <p></p>
        <Typography
            variant="h2"
            gutterBottom
            sx={{
                color: 'black',
                fontSize: '1.2rem',
                display: 'inline-block',
                transition: 'transform 0.3s ease',
                transformStyle: 'preserve-3d',
                    '&:hover': {
                      transform: 'rotateY(10deg) rotateX(10deg)',
                    },
                }}
        >
            Email your resume to <a href="mailto:career@broadwayorginals.com">career@broadwayorginals.com</a>
        </Typography>
        <Box sx={{ padding: 2 }}>
            <Grid container spacing={3}>
                {pagedata.map(job => (
                <Grid item xs={12} sm={6} md={6} key={job.id}>
                    <Card elevation={3} sx={{ height: '100%' }}>
                    <CardContent>
                        <Typography variant="h6" sx={{ color: 'rgb(0, 168, 170)' }}>
                        {job.title}
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'rgb(0, 168, 170)', mt: 1 }}>
                        Experience: {job.experience}
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'rgb(0, 168, 170)', mt: 1 }}>
                        Posted on: {job.post_on}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 2 }}>
                        {job.description}
                        </Typography>
                    </CardContent>
                    </Card>
                </Grid>
                ))}
            </Grid>
            </Box> 
        </Container>
      </Container>
      {pageFooter}
    </>
  );
};

export default Withus;
