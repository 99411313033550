import React, { useState } from 'react';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  Badge,
  Stack,
  Box,
  Divider
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'; // Solid icons
import { faFacebookF, faXTwitter, faLinkedinIn, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'; // Brand icons

const OffCanvas = ({ name, navcontent, socailmediainfo, options, onClose }) => {
  const [currentView, setCurrentView] = useState({ level: 'main', data: null }); // Manage view state

  const navcontent_arr = JSON.parse(navcontent);
  const socailmediainfo_arr = JSON.parse(socailmediainfo);

  const handleClickCategory = (category) => {
    if (category.data) {
      setCurrentView({ level: 'category', data: category });
    } else {
      window.location = "/" + category.custom_url;
    }
  };

  const handleClickCity = (category, city) => {
    setCurrentView({ level: 'city', data: { category, city } });
  };

  const handleClickSubCategory = (url) => {
    window.location = url;
  };

  const onclickOpenPageUrl = (pageurl) => {
    window.location = pageurl;
  };

  const handleBack = () => {
    if (currentView.level === 'city') {
      setCurrentView({ level: 'category', data: currentView.data.category });
    } else {
      setCurrentView({ level: 'main', data: null });
    }
  };

  return (
    <div style={{ padding: '20px', height: '100%', overflowY: 'auto', scrollbarWidth: 'thin', scrollbarColor: '#ccc #f5f5f5', background: "linear-gradient(177deg, #00a8aa14 0%, rgba(0, 0, 0, 0.04) 100%)" }}>
      {/* Close Button */}
      <IconButton onClick={onClose} style={{ marginBottom: '10px', }}>
        <FontAwesomeIcon icon={faTimes} />
      </IconButton>

      {/* Back Button - Only visible in category and city views */}
      {(currentView.level === 'category' || currentView.level === 'city') && (
        <IconButton onClick={handleBack} style={{ marginBottom: '20px', float: "right" }}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </IconButton>
      )}

      {currentView.level === 'main' && (
        <>
          {/* Welcome and Login/Sign Up section */}
          <div className='mb-2' style={{
            padding: '20px',
            background: 'rgba(25, 118, 210, 0.04)',
            fontFamily: 'Roboto',
            fontSize: '16px',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            marginBottom: '10px'
          }}>
            <div style={{ fontWeight: 'bold', color: '#004444' }}>Welcome To Broadway Originals</div>
            <div className='mt-2'>
              <u onClick={() => onclickOpenPageUrl('/new-user')} style={{ cursor: 'pointer', color: '#004444', fontWeight: "bold" }}>
                Login Or Sign Up
              </u>
            </div>
          </div>

          {/* Main Menu */}
          <List>
            {navcontent_arr.map((category) => (
              <ListItem
                key={category.category_name}
                button
                onClick={() => handleClickCategory(category)}
                style={{
                  borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                  cursor: 'pointer',
                  fontSize: '16px',
                  fontWeight: '600',
                  padding: '10px',
                  '&:hover': { backgroundColor: '#e3f2fd' }, // Hover effect
                }}
              >
                {category.category_name}
                <FontAwesomeIcon icon={faAngleRight} style={{ marginLeft: 'auto' }} />
              </ListItem>
            ))}
          </List>

          {/* Additional */}
          <List>
            <ListItem button onClick={() => onclickOpenPageUrl('/advertise')} style={{ fontSize: '14px', fontWeight: '600', padding: '10px', cursor: 'pointer' }}>
              Advertise
              <FontAwesomeIcon icon={faAngleRight} style={{ marginLeft: 'auto' }} />
            </ListItem>
            <ListItem button onClick={() => onclickOpenPageUrl('/work-with-us')} style={{ fontSize: '14px', fontWeight: '600', padding: '10px', cursor: 'pointer' }}>
              Work With Us
              <FontAwesomeIcon icon={faAngleRight} style={{ marginLeft: 'auto' }} />
            </ListItem>
            <ListItem button onClick={() => onclickOpenPageUrl('/about')} style={{ fontSize: '14px', fontWeight: '600', padding: '10px', cursor: 'pointer' }}>
              About Us
              <FontAwesomeIcon icon={faAngleRight} style={{ marginLeft: 'auto' }} />
            </ListItem>
            <ListItem button onClick={() => onclickOpenPageUrl('/contact')} style={{ fontSize: '14px', fontWeight: '600', padding: '10px', cursor: 'pointer' }}>
              Contact Us
              <FontAwesomeIcon icon={faAngleRight} style={{ marginLeft: 'auto' }} />
            </ListItem>
          </List>
          <Divider />
          {/* Social Media Icons */}
          <Stack direction="row" spacing={1} className="mt-1" sx={{ justifyContent: 'center', mt: 2 }}>
            {socailmediainfo_arr.map((mediadata) => (
              <Badge
                key={mediadata.name}
                onClick={() => window.location = mediadata.link}
                sx={{ fontSize: '18px', cursor: 'pointer', background: 'rgba(25, 118, 210, 0.04) !important' }}
              >
                <FontAwesomeIcon
                  icon={
                    mediadata.name === 'facebook'
                      ? faFacebookF
                      : mediadata.name === 'twitter'
                      ? faXTwitter
                      : mediadata.name === 'linkedin'
                      ? faLinkedinIn
                      : mediadata.name === 'instagram'
                      ? faInstagram
                      : faYoutube
                  }
                />
              </Badge>
            ))}
          </Stack>
        </>
      )}

      {currentView.level === 'category' && (
        <List>
          {currentView.data.category_name === 'City Guides' &&
            currentView.data.data.cities.map((city) => (
              <ListItem button key={city.name} onClick={() => handleClickCity(currentView.data, city)} style={{ padding: '15px 10px', cursor: 'pointer' }}>
                {city.name}
                <FontAwesomeIcon icon={faAngleRight} style={{ marginLeft: 'auto' }} />
              </ListItem>
            ))}

          {currentView.data.category_name !== 'City Guides' &&
            currentView.data.data.sub_categories.map((sub_category) => (
              <ListItem button key={sub_category.name} onClick={() => handleClickSubCategory('/' + currentView.data.custom_url + '/' + sub_category.custom_url)} style={{ padding: '15px 10px',cursor: 'pointer' }}>
                {sub_category.name}
              </ListItem>
            ))}
        </List>
      )}

      {currentView.level === 'city' && (
        <List>
          {currentView.data.category.data.sub_categories.map((sub_category) => (
            <ListItem button key={sub_category.name} onClick={() => handleClickSubCategory(`/${currentView.data.category.custom_url}/${currentView.data.city.urlstring}/${sub_category.custom_url}`)} style={{ padding: '15px 10px', cursor: 'pointer' }}>
              {sub_category.name}
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
};

export default OffCanvas;
