import React from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Divider,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const ProsConsBlock = ({ pros_cons }) => {
  return (
    <Grid container spacing={2} className="mt-5">
      <Grid item xs={12}>
        {pros_cons && pros_cons.length > 0 && (
          <Paper
            elevation={3}
            sx={{
              backgroundColor: 'rgb(228, 228, 228)',
              padding: 3,
              borderRadius: 2,
              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                    fontFamily: 'Roboto',
                    color: 'rgb(19, 19, 19)',
                    marginBottom: 1,
                  }}
                >
                  Pros
                </Typography>
                <List>
                  {pros_cons.map((pros) => (
                    pros.category === "1" && (
                      <ListItem
                        key={pros.description}
                        sx={{
                          padding: 1,
                          '&:hover': {
                            backgroundColor: 'rgba(0, 255, 0, 0.1)', // Light green on hover
                            transition: 'background-color 0.3s ease',
                          },
                        }}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faCheck} style={{ color: 'green', fontSize: '1.5rem' }} />
                        </ListItemIcon>
                        <Typography
                          sx={{
                            color: 'rgb(19, 19, 19)',
                            fontWeight: 400,
                            fontSize: '1.25rem',
                            lineHeight: '2rem',
                            fontFamily: 'Roboto',
                          }}
                        >
                          {pros.description}
                        </Typography>
                      </ListItem>
                    )
                  ))}
                </List>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                    fontFamily: 'Roboto',
                    color: 'rgb(19, 19, 19)',
                    marginBottom: 1,
                  }}
                >
                  Cons
                </Typography>
                <List>
                  {pros_cons.map((cons) => (
                    cons.category === "0" && (
                      <ListItem
                        key={cons.description}
                        sx={{
                          padding: 1,
                          '&:hover': {
                            backgroundColor: 'rgba(255, 0, 0, 0.1)', // Light red on hover
                            transition: 'background-color 0.3s ease',
                          },
                        }}
                      >
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faCheck} style={{ color: 'red', fontSize: '1.5rem' }} />
                        </ListItemIcon>
                        <Typography
                          sx={{
                            color: 'rgb(19, 19, 19)',
                            fontWeight: 400,
                            fontSize: '1.25rem',
                            lineHeight: '2rem',
                            fontFamily: 'Roboto',
                          }}
                        >
                          {cons.description}
                        </Typography>
                      </ListItem>
                    )
                  ))}
                </List>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};

export default ProsConsBlock;
