import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress,
  TextField,
  Button, Avatar, useMediaQuery, useTheme
} from '@mui/material';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import CardOverflow from '@mui/joy/CardOverflow';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import ShareIcon from '@mui/icons-material/Share';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Header from '../components/Header';
import MainNav from '../components/MainNav';
import Footer from '../components/Footer';
import MetaTags from '../components/MetaTags';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Advertise = () => {
  const [loaderState, setLoaderState] = useState(true);
  const [navlist, setNavlist] = useState(null);
  const [mainnavlist, setMainNavlist] = useState(null);
  const [pageFooter, setPageFooter] = useState(null);
  const [pagemetatags, setPageMetaTags] = useState([]);
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    phonenumber: '',
    companyname: '',
    website: '',
    adsize: ''
 });
const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value
    });
};

const handleSubmitT = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post('https://broadwayoriginals.com/apis/index.php', formData);
        if (response && response.data.status == "1") {
            toast.success(response.data.msg);
            setFormData({
                fullname: '',
                email: '',
                phonenumber: '',
                companyname: '',
                website: '',
                adsize: ''
            });
        } else {
            toast.error(response.data.msg);
        }
    } catch (error) {
        console.log(error);
        toast.error("Error submitting form");
    }
};


  const fetchPageData = async () => {
    try {
      setLoaderState(true);
      const response = await axios.get('https://broadwayoriginals.com/apis/index.php?contactus');
      document.title = "Broadway Originals | Contact Us";
      const responseData = response?.data || {};

      setNavlist(
        <Header
          navlist={JSON.stringify(responseData.navlist || {})}
          socailmedia={JSON.stringify(responseData.socailmedia || {})}
        />
      );
      setMainNavlist(<MainNav navlist={JSON.stringify(responseData.navlist || {})} />);
      setPageFooter(
        <Footer
          nav_list={JSON.stringify(responseData.navlist || {})}
          socailmedia={JSON.stringify(responseData.socailmedia || {})}
        />
      );
      setPageMetaTags(responseData.pagemetatags || []);
      setLoaderState(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoaderState(false);
    }
  };

  useEffect(() => {
    fetchPageData();
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          padding: '0px !important',
          background: 'url(https://source.unsplash.com/random/1920x1080)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100vh',
          color: '#fff',
        }}
      >
        <ToastContainer />
        {navlist}
        {mainnavlist}
        <MetaTags tags={pagemetatags} />
        {loaderState && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
            <CircularProgress />
          </Box>
        )}
        {!loaderState && (
          <Container maxWidth="lg" sx={{ padding: { xs: '20px', md: '0px' }, marginTop: '40px', color: 'black' }}>          
            <Box sx={{ padding: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Card
                    size="lg"
                    variant="plain"
                    orientation={isMobile ? "vertical" : "horizontal"}
                    sx={{
                      textAlign: 'left',
                      maxWidth: '100%',
                      // to make the demo resizable
                      resize: 'horizontal',
                    }}
                  >  
                    <CardContent sx={{ gap: 1.5, minWidth: 100 }}>
                      <CardContent>
                      <Typography
                        variant="h2"
                        gutterBottom
                        sx={{
                          color: 'rgb(0, 123, 124)',
                          fontSize: '1.75rem',
                          fontWeight: 'bold',
                          display: 'inline-block',
                          transition: 'transform 0.3s ease',
                          transformStyle: 'preserve-3d',
                          textAlign: 'left',
                          '&:hover': {
                            transform: 'rotateY(10deg) rotateX(10deg)',
                          },
                        }}
                      >
                        Advertise
                      </Typography>
                      <form onSubmit={handleSubmitT}>
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Full Name"
                                    name="fullname"
                                    value={formData.fullname}
                                    onChange={handleChange}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Email Address"
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Phone Number"
                                    name="phonenumber"
                                    value={formData.phonenumber}
                                    onChange={handleChange}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Company Name"
                                    name="companyname"
                                    value={formData.companyname}
                                    onChange={handleChange}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Website"
                                    name="website"
                                    value={formData.website}
                                    onChange={handleChange}
                                />
                                <FormControl component="fieldset" margin="normal">
                                    <Typography style={{ color: "#131313", marginBottom: '8px' }}>Choose Ad size</Typography>
                                    <RadioGroup
                                        row
                                        name="adsize"
                                        value={formData.adsize}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel
                                            value="1200 X 300 - $40"
                                            control={<Radio color="primary" />}
                                            label="1200 X 300 - $40"
                                        />
                                        <FormControlLabel
                                            value="300 X 300 - $10"
                                            control={<Radio color="primary" />}
                                            label="300 X 300 - $10"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <Box >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className="submit-btn"
                                        style={{ background: "#00A8AA", color: "white", marginTop: "16px", width: '100%' }}
                                    >
                                        Send
                                    </Button>
                                </Box>
                            </form>
                      </CardContent>
                      
                    </CardContent>
                    <CardOverflow
                      variant="solid"
                      color="primary"
                      sx={{
                        flex: '0 0 50%',
                        display: 'flex',
                        flexDirection: 'column',
                        px: 'var(--Card-padding)',
                        background: '#0086861c',
                      }}
                    >
                      
                      <Box display="flex" mb={3}>
                        <Box ml={2}>
                            <Typography variant="h6" style={{ color: "#00A8AA"}}>
                                Disclaimer
                            </Typography>
                            <Typography variant="body1" sx={{color: 'black', mt: 2}}>
                                After submitting your request, our team will reach out to you in 24 hours on email or phone for images and further details.
                            </Typography>
                        </Box>
                      </Box>
                    </CardOverflow> 
                  </Card>
                </Grid>
              </Grid> 
             
            </Box>
          </Container>
        )}
      </Container>
      {pageFooter}
    </>
  );
};

export default Advertise;
