import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import {
  Container, Grid, CardMedia, Typography, Box, Avatar, CircularProgress,
  Button, LinearProgress
} from '@mui/material';
import { Facebook, Twitter, Instagram, LinkedIn, YouTube } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MainNav from '../components/MainNav';
import RecipeBlock from '../components/RecipeBlock';
import ProsConsBlock from '../components/ProsConsBlock';
import ProductReferenceBlock from '../components/ProductReferenceBlock';
import '../css/PostView.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { People, Visibility } from '@mui/icons-material'
import AspectRatio from '@mui/joy/AspectRatio';
import Link from '@mui/joy/Link';
import CardContent from '@mui/joy/CardContent';
import Chip from '@mui/joy/Chip';
import Card from '@mui/joy/Card'
import CardCover from '@mui/joy/CardCover';
import lightbox from 'lightbox2';
import 'lightbox2/dist/css/lightbox.css';
import htmlParser from 'html-react-parser';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
const PostView = () => {
  const printRef = useRef();
  const { posturl } = useParams();
  const [pagecontent, setPageContent] = useState('');
  const [post, setPost] = useState(null);
  const [loaderState, setLoaderState] = useState(true);
  const [navlist, setNavlist] = useState(null);
  const [mainNavlist, setMainNavlist] = useState(null);
  const [pageFooter, setPageFooter] = useState(null);
  const [authorImg, setAuthorImg] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [postImage, setPostImage] = useState("");
  const [followers, setFollowers] = useState("0");
  const [views, setViews] = useState("0");
  const [socialMedia, setSocialMedia] = useState({});
  const [theReviews, setTheReviews] = useState([]);
  const [reviewBreakdown, setReviewBreakdown] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [prosCons, setProsCons] = useState([]);
  const [productReference, setProductReference] = useState([]);
  const [trendingPosts, setTrendingPosts] = useState([]);
  const [todayNews, setTodayNews] = useState([]);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [postAddedOn, setPostAddedOn] = useState("");
  const [galleryImages, setGalleryImages] = useState([]); // Store gallery images
  const [galleryContent, setGalleryContent] = useState(''); // Store modified content
  const handlePrint = () => {
    const printContent = printRef.current;
    const printWindow = window.open('', '', 'width=800,height=600');

    // Get all CSS styles from the current page
    const styles = Array.from(document.styleSheets)
      .map((styleSheet) => {
        try {
          return Array.from(styleSheet.cssRules)
            .map((rule) => rule.cssText)
            .join('');
        } catch (e) {
          console.error(e);
          return '';
        }
      })
      .join('');

    printWindow.document.write(`
      <html>
        <head>
          <title>Print Recipe</title>
          <style>${styles}</style>
        </head>
        <body>
          ${printContent.innerHTML}
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };
  const handleBuy = (link) => {
    window.open(link, '_blank');
  };
  const convertGallery = (content) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = content;

    const galleryElements = tempElement.querySelectorAll('.bdo-custom-gallery img');
    const images = Array.from(galleryElements).map((img) => img.src); // Extract image URLs

    setGalleryImages(images); // Store images in state for rendering Gallery

    // Replace the original gallery block with the React Gallery component placeholder
    const galleryBlock = tempElement.querySelector('.bdo-custom-gallery');
    if (galleryBlock) {
      galleryBlock.innerHTML = '<div id="react-gallery"></div>'; // Placeholder for React Gallery
    }

    return tempElement.innerHTML;
  };
  const fetchPostData = async () => {
    try {
      const response = await axios.get(`https://broadwayoriginals.com/apis/index.php?post_url=${posturl}`);
      const postData = response.data.pagedata;

      document.title = "Broadway Originals | " + postData.post_title;

      setPost(postData);
      let modifiedContent = response.data.pagedata.post_content;
      modifiedContent = convertGallery(modifiedContent);
      setPageContent(modifiedContent);
      setAuthorImg(postData.author_img ? `https://broadwayoriginals.com/${postData.author_img}` : "");
      setAuthorName(postData.author_name || "Unknown Author");
      setPostImage(postData.post_image ? `https://broadwayoriginals.com/${postData.post_image}` : "");
      setFollowers(postData.post_followers || "0");
      setViews(postData.post_views || "0");
      setSocialMedia({
        facebook: postData.facebook || '',
        twitter: postData.twitter || '',
        instagram: postData.instagram || '',
        linkedin: postData.linkedin || '',
        youtube: postData.youtube || '',
      });
      setTheReviews(postData.the_reviews || []);
      setReviewBreakdown(postData.review_breakdown || []);
      setRecipes(postData.recipes || []);
      setProductReference(postData.product_reference || []);
      setProsCons(postData.pros_cons || []);
      setTrendingPosts(response.data.trending || []);
      setTodayNews(response.data.todaynews || []);
      setRelatedPosts(postData.related_posts || []);
      setPostAddedOn(postData.post_added_on);
      setNavlist(<Header navlist={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)} />);
      setMainNavlist(<MainNav navlist={JSON.stringify(response.data.navlist)} />);
      setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)} />);
      setLoaderState(false);
    } catch (error) {
      console.error('Error fetching post data:', error);
      setLoaderState(false);
    }
  };

  useEffect(() => {
    fetchPostData();
  }, [posturl]);

  if (loaderState) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth={false} sx={{ padding: '0px !important', backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
      {navlist}
      {mainNavlist}
      <Container sx={{ mt: 5 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card>
              <CardMedia
                component="img"
                height="400"
                image={postImage}
                alt={post.post_title}
                sx={{ objectFit: 'cover' }}
              />
            </Card>
          </Grid>
          
          {/* Main Post Content */}
          <Grid item xs={12} md={9}>
            <Card sx={{background: 'none'}}>
              <Box sx={{ padding: 2 }}>
                <Typography variant="body1" dangerouslySetInnerHTML={{ __html: pagecontent }}  />           
              </Box>
            </Card>
            {/* Reviews */}
            {theReviews.length > 0 && (
              <Card variant="outlined" sx={{ mt: 2, background: 'none' }}>
                <Box > {/* Padding for the card content */}
                  <Typography variant="h5" >Reviews</Typography>
                  {theReviews.map((review) => (
                    <Card
                      key={review.review_title}
                      variant="outlined"
                      sx={{
                        width: '100%',
                        background: 'none',
                        border: 'none',
                        mt: 2,
                        '&:hover': { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' },
                        display: 'flex', // Align items horizontally
                        flexDirection: 'row', // Horizontal layout
                      }}
                    >
                      <Box
                        sx={{
                          width: 90,
                          height: 90,
                          display: 'flex',
                          flexDirection: 'column', // Arrange text vertically
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: 'rgb(0, 168, 170)', // Background color
                          borderRadius: 1,
                        }}
                      >
                        <Typography variant="h6" color="white">
                          {review.review_percentage}%
                        </Typography>
                        <Typography variant="body2" color="white"> {/* Add score text below percentage */}
                          Score
                        </Typography>
                      </Box>
                      <CardContent sx={{ flex: 1 }}> {/* Flex property to fill remaining space */}
                        <Typography
                          level="title-lg"
                          id="card-description"
                          sx={{ fontWeight: 'bold', fontSize: '1.3rem' }}
                        >
                          {review.review_title}
                        </Typography>
                        <Typography level="body-sm" aria-describedby="card-description" sx={{ mb: 1 }}>
                          {review.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                </Box>
              </Card>
            )}


            {/* Review Breakdown */}
            {reviewBreakdown.length > 0 && (
              <Card sx={{ mt: 4, p: 2, background: 'none' }}>
                <CardContent>
                  <Typography variant="h5" sx={{mb: 3}}>Review Breakdown</Typography>
                  {reviewBreakdown.map((breakdown) => (
                    <Box key={breakdown.topic} sx={{ mb: 2 }}>
                      <Typography>{breakdown.topic}</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <LinearProgress
                          variant="determinate"
                          value={parseFloat(breakdown.percentage)}
                          sx={{
                            flexGrow: 1,
                            height: 10,
                            borderRadius: 10,
                            backgroundColor: '#e0e0e0',
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: 'rgba(0, 168, 170)',
                              borderRadius: 10,
                            },
                          }}
                        />
                        <Typography>{breakdown.percentage}%</Typography>
                      </Box>
                    </Box>
                  ))}
                </CardContent>
              </Card>
            )}


            {/* Recipes */}
            {recipes.length > 0 && (
              <Box sx={{ mt: 4 }}>
                <RecipeBlock recipes={recipes} handlePrint={handlePrint} printRef={printRef} />
              </Box>
            )}

            {/* Pros and Cons */}
            {prosCons.length > 0 && (
              <Box sx={{ mt: 4 }}>
                <ProsConsBlock pros_cons={prosCons} />
              </Box>
            )}

            {/* Product References */}
            {productReference.length > 0 && (
              <Box sx={{ mt: 4 }}>
                <ProductReferenceBlock product_reference={productReference} handleBuy={handleBuy} />
              </Box>
            )}
          </Grid>

          {/* Author Information */}
          <Grid item xs={12} md={3}>
            {authorImg && (
              <Card sx={{ padding: 3, textAlign: 'center', mt: 4, boxShadow: 2, borderRadius: 2, marginTop: '0px', background: 'none' }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Article by
              </Typography>
              <CardMedia
                component="img"
                height="140"
                image={authorImg}
                alt={authorName}
                sx={{
                  borderRadius: '50%',
                  margin: '0 auto',
                  width: '100px',
                  height: '100px',
                  boxShadow: 3,
                }}
              />
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {authorName}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mx: 2 }}>
                    <People sx={{ mr: 0.5 }} />
                    <Typography variant="body2" color="text.secondary">
                      {followers}
                    </Typography>
                  </Box>
                  <span>|</span>
                  <Box sx={{ display: 'flex', alignItems: 'center', mx: 2 }}>
                    <Visibility sx={{ mr: 0.5 }} />
                    <Typography variant="body2" color="text.secondary">
                      {views}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                    {socialMedia.facebook && (
                        <Avatar sx={{background: '#00a8a9', mr: 1, fontSize: '15px', width: '34px', height: '34px'}}>
                            <FontAwesomeIcon
                              icon={faFacebookF}
                            />
                        </Avatar>
                    )}
                    {socialMedia.twitter && (
                      <Avatar sx={{background: '#00a8a9', mr: 1, fontSize: '15px', width: '34px', height: '34px'}}>
                        <FontAwesomeIcon
                          icon={faTwitter}
                        />
                      </Avatar>
                    )}
                    {socialMedia.instagram && (
                      <Avatar sx={{background: '#00a8a9', mr: 1, fontSize: '15px', width: '34px', height: '34px'}}>
                        <FontAwesomeIcon
                          icon={faInstagram}
                        />
                      </Avatar>
                    )}
                    {socialMedia.linkedin && (
                        <Avatar sx={{background: '#00a8a9', mr: 1, fontSize: '15px', width: '34px', height: '34px'}}>
                          <FontAwesomeIcon
                            icon={faLinkedinIn}
                          />
                        </Avatar>
                    )}
                    {socialMedia.youtube && (
                      <Avatar sx={{background: '#00a8a9', mr: 1, fontSize: '15px', width: '34px', height: '34px'}}>
                        <FontAwesomeIcon
                          icon={faYoutube}
                        />
                      </Avatar>
                    )}
                  </Box>
                </Box>
              </Box>
            </Card>          
            )}
            {/* Hot Categories */}
            <Card sx={{ padding: 2, mt: 4, background: 'none' }}>
              <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
                Hot Categories
              </Typography>
              <Grid container>
                <Card component="li" sx={{ minWidth: '100%', flexGrow: 1, mb: 2 }}>
                  <CardCover>
                    <img
                      src="https://broadwayoriginals.com/static/media/movies.9d3f75aaf05ec666596b.png"
                      srcSet="https://broadwayoriginals.com/static/media/movies.9d3f75aaf05ec666596b.png"
                      loading="lazy"
                      alt=""
                      style={{ width: '100%' }}
                    />
                  </CardCover>
                  <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                    <Typography
                      level="body-lg"
                      textColor="#ffffff"
                      sx={{ fontWeight: 'bold', fontSize: '20px', color: '#ffffff', mt: 0 }}
                    >
                      <a href="/entertainment" style={{ color: 'inherit', textDecoration: 'none' }}>Entertainment</a>
                    </Typography>
                  </CardContent>
                </Card>
                <Card component="li" sx={{ minWidth: '100%', flexGrow: 1, mb: 2 }}>
                  <CardCover>
                    <img
                      src="https://broadwayoriginals.com/static/media/koreya.702c67442116a30c3012.png"
                      srcSet="https://broadwayoriginals.com/static/media/koreya.702c67442116a30c3012.png"
                      loading="lazy"
                      alt=""
                      style={{ width: '100%' }}
                    />
                  </CardCover>
                  <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                    <Typography
                      level="body-lg"
                      textColor="#ffffff"
                      sx={{ fontWeight: 'bold', fontSize: '20px', color: '#ffffff', mt: 0 }}
                    >
                      <a href="/life-style" style={{ color: 'inherit', textDecoration: 'none' }}>Life Style</a>
                    </Typography>
                  </CardContent>
                </Card>
                <Card component="li" sx={{ minWidth: '100%', flexGrow: 1, mb: 2 }}>
                  <CardCover>
                    <img
                      src="https://broadwayoriginals.com/static/media/tech.bfe01c4ec569fdfc7c0d.png"
                      srcSet="https://broadwayoriginals.com/static/media/tech.bfe01c4ec569fdfc7c0d.png"
                      loading="lazy"
                      alt=""
                      style={{ width: '100%' }}
                    />
                  </CardCover>
                  <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                    <Typography
                      level="body-lg"
                      textColor="#ffffff"
                      sx={{ fontWeight: 'bold', fontSize: '20px', color: '#ffffff', mt: 0 }}
                    >
                      <a href="/technology" style={{ color: 'inherit', textDecoration: 'none' }}>Tech</a>
                    </Typography>
                  </CardContent>
                </Card>
                <Card component="li" sx={{ minWidth: '100%', flexGrow: 1 }}>
                  <CardCover>
                    <img
                      src="https://broadwayoriginals.com/static/media/videos.47205ac06599366e4476.png"
                      srcSet="https://broadwayoriginals.com/static/media/videos.47205ac06599366e4476.png"
                      loading="lazy"
                      alt=""
                      style={{ width: '100%' }}
                    />
                  </CardCover>
                  <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                    <Typography
                      level="body-lg"
                      textColor="#ffffff"
                      sx={{ fontWeight: 'bold', fontSize: '20px', color: '#ffffff', mt: 0 }}
                    >
                      <a href="/videos" style={{ color: 'inherit', textDecoration: 'none' }}>Videos</a>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Card>


            {/* Trending Posts */}
            {trendingPosts.length > 0 && (
              <Card sx={{ padding: 2, mt: 4, background: 'none', }}>
                <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
                  Trending
                </Typography>
                <Grid container spacing={2}>
                  {trendingPosts.map((trendingPost) => (
                    <Grid item xs={12} sm={12} md={12} key={trendingPost.post_id}>
                      <Card
                        variant="outlined"
                        orientation="horizontal"
                        sx={{
                          display: 'flex',
                          background: 'none',
                          width: '100%',
                          '&:hover': { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' },
                        }}
                      >
                        <AspectRatio ratio="1" sx={{ width: 90 }}>
                          <img
                            src={`https://broadwayoriginals.com/${trendingPost.post_img}`}
                            loading="lazy"
                            alt={trendingPost.post_title}
                            style={{ borderRadius: '6px' }} // For rounding corners of the image
                          />
                        </AspectRatio>
                        <CardContent>
                        <Link href={`/${trendingPost.custom_url}`} underline="hover" color="inherit" sx={{ flexGrow: 1 }}>
                          <Typography
                            level="title-lg"
                            id="card-description"
                            fontWeight="bold"
                            sx={{
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              WebkitLineClamp: 4, // Limit to 4 lines
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {trendingPost.post_title}
                          </Typography>
                        </Link>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Card>
            )}
          {/* Today's News */}
          {todayNews.length > 0 && (
              <Card sx={{ padding: 2, mt: 4, background: 'none', }}>
                <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
                  Today's News
                </Typography>
                <Grid container spacing={2}>
                  {todayNews.map((newsPost) => (
                    <Grid item xs={12} sm={12} md={12} key={newsPost.post_id}>
                      <Card
                        variant="outlined"
                        orientation="horizontal"
                        sx={{
                          display: 'flex',
                          background: 'none',
                          width: '100%',
                          '&:hover': { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' },
                        }}
                      >
                        <AspectRatio ratio="1" sx={{ width: 90 }}>
                          <img
                            src={`https://broadwayoriginals.com/${newsPost.post_img}`}
                            loading="lazy"
                            alt={newsPost.post_title}
                            style={{ borderRadius: '6px' }} // For rounding corners of the image
                          />
                        </AspectRatio>
                        <CardContent>
                        <Link href={`/${newsPost.custom_url}`} underline="hover" color="inherit" sx={{ flexGrow: 1 }}>
                          <Typography
                            level="title-lg"
                            id="card-description"
                            fontWeight="bold"
                            sx={{
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              WebkitLineClamp: 4, // Limit to 4 lines
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {newsPost.post_title}
                          </Typography>
                        </Link>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Card>
            )}
          </Grid>
        </Grid>
      </Container>
      {pageFooter}
    </Container>
  );
};

export default PostView;
