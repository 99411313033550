import React from 'react';
import {
  Box,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Badge,
  Avatar,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from 'react-router-dom';
import health_img from '../images/Health.svg';

function Health({ todaynews }) {
  const todaynews_list = JSON.parse(todaynews);

  const onclickOpenHealth = () => {
    window.location = "/health";
  };

  return (
    <>
      {todaynews_list.length > 0 && (
        <Container maxWidth="lg" sx={{ mb: 2 }}>
          {/* Section Header */}
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} md={10}>
              <Typography
                variant="h4"
                className="block-header"
                sx={{
                  color: "#00a8a9",
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: { xs: '24px', sm: '28px', md: '32px' }, // Responsive font size
                  fontWeight: 'bold',
                  textAlign: { xs: 'center', md: 'left' }, // Center on small screens, left on large
                }}
              >
                <img src={health_img} alt="" style={{ width: "4%", marginRight: "5px" }} />
                Health
              </Typography>
            </Grid>
            <Grid item xs={12} md={2} sx={{ textAlign: 'end', display: { xs: 'none', md: 'block' } }}>
              <Badge
                sx={{
                  fontSize: "18px",
                  background: "white",
                  color: "#00a8a9",
                  borderRadius: "20px",
                  padding: "10px",
                  cursor: "pointer",
                }}
                onClick={onclickOpenHealth}
              >
                <span style={{ color: "#00a8a9", fontWeight: 200 }}>
                  Explore More <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: "15px", paddingLeft: "10px" }} />
                </span>
              </Badge>
            </Grid>
          </Grid>

          {/* News Grid */}
          <Grid container spacing={2} sx={{ alignItems: 'stretch' }}>
            {/* First Block (Featured Article) */}
            <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
              {todaynews_list.map((item, index) => (
                index === 0 && (
                  <Card
                    key={item.id}
                    sx={{
                      border: "none",
                      borderRadius: "20px",
                      mb: 2,
                      background: "transparent", // Remove background color
                      boxShadow: 'none', // Remove shadow
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                      transition: "transform 0.3s ease",
                      '&:hover': {
                        transform: "scale(1.03)", // Scale on hover
                      },
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={`https://broadwayoriginals.com/${item.post_img}`}
                      loading="lazy"
                      sx={{
                        borderRadius: "20px",
                        height: { xs: '240px', md: '357px' }, // Responsive height for image
                        objectFit: 'cover',
                      }}
                    />
                    <CardContent sx={{ padding: "16px" }}>
                      <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                          src={`https://broadwayoriginals.com/${item.author.img}`}
                          alt={item.author.name}
                          sx={{ width: 30, height: 30, mr: 1 }}
                        />
                        <span style={{ color: "rgb(50, 19, 19)" }}>{item.author.name}</span>
                      </Typography>
                      <Typography
                        variant="h6"
                        component={NavLink}
                        to={item.custom_url}
                        sx={{
                          textDecoration: 'none',
                          color: 'inherit',
                          fontWeight: 'bold',
                          marginTop: '8px',
                          display: 'block', // Changed to block
                          overflow: 'hidden',
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                          width: '100%', // Set width to 100%
                          maxHeight: '48px', // Limit the height to allow for line clamping
                          overflow: 'hidden',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2, // Clamps the text to 2 lines
                        }}
                      >
                        {item.post_title}
                      </Typography>
                    </CardContent>
                  </Card>
                )
              ))}
            </Grid>

            {/* Second Block (List of Articles) */}
            <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
              {todaynews_list.map((item1, index1) => (
                index1 !== 0 && (
                  <Card
                    key={item1.id}
                    sx={{
                      display: 'flex',
                      mb: 2,
                      borderRadius: '20px',
                      flexDirection: 'row',
                      background: "transparent", // Remove background color
                      boxShadow: 'none', // Remove shadow
                      transition: "transform 0.3s ease",
                      '&:hover': {
                        transform: "scale(1.03)", // Scale on hover
                      },
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={`https://broadwayoriginals.com/${item1.post_img}`}
                      alt={item1.post_title}
                      sx={{
                        width: { xs: '120px', sm: '151px' }, // Responsive width for image
                        height: 'auto',
                        borderRadius: '20px',
                      }}
                    />
                    <CardContent sx={{ flex: '1 0 auto', padding: 1 }}>
                      <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                          src={`https://broadwayoriginals.com/${item1.author.img}`}
                          alt={item1.author.name}
                          sx={{ width: 30, height: 30, mr: 1 }}
                        />
                        <span style={{ color: "rgb(50, 19, 19)" }}>{item1.author.name}</span>
                      </Typography>
                      <Typography
                        variant="h6"
                        component={NavLink}
                        to={item1.custom_url}
                        sx={{
                          textDecoration: 'none',
                          color: 'inherit',
                          fontWeight: 'bold',
                          marginTop: '8px',
                          display: 'block', // Changed to block
                          overflow: 'hidden',
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                          width: '100%', // Set width to 100%
                          maxHeight: '48px', // Limit the height to allow for line clamping
                          overflow: 'hidden',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2, // Clamps the text to 2 lines
                        }}
                      >
                        {item1.post_title}
                      </Typography>
                    </CardContent>
                  </Card>
                )
              ))}
            </Grid>
          </Grid>

          {/* Mobile-Only Explore Button */}
          <Grid container justifyContent="flex-end" sx={{ mt: 2, display: { xs: 'flex', md: 'none' } }}>
            <Grid item xs={12} sx={{ padding: 0 }}>
              <Badge
                sx={{
                  fontSize: "18px",
                  background: "none",
                  color: "#00a8a9",
                  borderRadius: "20px",
                  padding: "10px",
                  cursor: "pointer",
                  border: "1px solid #00a8a9",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={onclickOpenHealth}
              >
                <span style={{ color: "#00a8a9", fontWeight: 200 }}>Explore More</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Badge>
            </Grid>
          </Grid>

        </Container>
      )}
    </>
  );
}

export default Health;
