import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Box
} from '@mui/material';

const ProductReferenceBlock = ({ product_reference, handleBuy }) => {
  return (
    <Grid container spacing={3} sx={{mb: 3}}>
      <Grid item xs={12}>
        <Card
          sx={{
            border: '1px solid #E5E5E5',
            borderRadius: 2,
            padding: 2,
            background: 'none !important',
            boxShadow: 'none'
          }}
        >
          {product_reference && product_reference.length > 0 && product_reference.map((product) => (
            <CardContent key={product.product_name} className="mb-2">
              <Typography
                variant="h5"
                sx={{
                  fontFamily: 'Roboto',
                  fontWeight: 'bold',
                  lineHeight: '40px',
                  color: '#131313',
                  mb: 2,
                }}
              >
                {product.product_name}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '1.25rem',
                  lineHeight: '1.75rem',
                  fontWeight: '400',
                  color: '#555',
                  mb: 2
                }}
              >
                {product.description}
              </Typography>
              {product.references && product.references.length > 0 && (
                <List sx={{ padding: 0 }}>
                  {product.references.map((reference, index) => (
                    <ListItem key={reference.refer_site} disablePadding>
                    <ListItemButton>
                      <Grid container alignItems="center" spacing={2} sx={{ width: '100%' }}>
                        <Grid item>
                          <ListItemAvatar>
                            <Avatar
                              alt={reference.refer_site}
                              src={`https://broadwayoriginals.com/${reference.refer_img}`}
                            />
                          </ListItemAvatar>
                        </Grid>
              
                        <Grid item xs>
                          <ListItemText
                            id={`checkbox-list-label-${index}`}
                            primary={reference.refer_site}
                            primaryTypographyProps={{
                              sx: {
                                color: '#131313',
                                fontFamily: 'Roboto',
                                fontSize: '20px',
                                fontWeight: '500',
                                margin: 0, // Remove default margin for better alignment
                              },
                            }}
                          />
                        </Grid>
              
                        <Grid item>
                          <Typography
                            sx={{
                              fontFamily: 'Roboto',
                              fontSize: '18px',
                              fontWeight: 'bold',
                              color: '#555', // Lighter color for the cost
                            }}
                          >
                            {reference.cost}
                          </Typography>
                        </Grid>
              
                        <Grid item>
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: '#00A8AA',
                              color: 'white',
                              borderRadius: 2,
                              fontSize: '16px',
                              padding: '6px 12px',
                              '&:hover': {
                                backgroundColor: '#009C9A',
                                boxShadow: 2,
                              },
                            }}
                            onClick={() => handleBuy(reference.refer_link)}
                          >
                            Buy Now
                          </Button>
                        </Grid>
                      </Grid>
                    </ListItemButton>
                  </ListItem>
                  ))}
                </List>
              )}
            </CardContent>
          ))}
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProductReferenceBlock;
